import { Worker } from 'models/models';
import React, { useEffect, useState } from 'react';
import { sortString } from 'utils/logic';
import './WorkerSelection.css';

interface WorkerSelectionProps {
	workers: Worker[];
	selectedNode: Worker | undefined;
	setWorker: React.Dispatch<React.SetStateAction<string>>;
	selectedBuilder?: Worker | undefined;
}

const WorkerSelection: React.FC<WorkerSelectionProps> = (props) => {
	const { workers, selectedNode, setWorker, selectedBuilder = undefined } = props;

	const [ workerToDisplay, setWorkerToDisplay ] = useState<string | undefined>();

	useEffect(
		() => {
			if(selectedBuilder){
				setWorkerToDisplay(selectedBuilder.worker_id.name);
				setWorker(selectedBuilder.worker_id.name)
			}
		},
		[ selectedBuilder ]
	);

	const sortWorkerName = (selectedNode: Worker) => {
		let builderNames: string[] = [];
		workers.forEach((worker: Worker) => {
			if (worker.node_id === selectedNode.node_id) {
				if (!builderNames.includes(worker.worker_id.name)) {
					builderNames.push(worker.worker_id.name);
				}
			}
		});
		const sortBuilderNames = builderNames.sort((option1, option2) => {
			return sortString(option1, option2);
		});
		return sortBuilderNames;
	};

	const handelOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value;
		setWorker(value);
		setWorkerToDisplay(value);
	};

	return (
		<div>
			{selectedNode && (
				<select className="select" value={workerToDisplay} onChange={handelOnChange}>
					<option value="">Select Worker</option>
					{sortWorkerName(selectedNode).map((name: string, i: number) => (
						<option value={name} key={i}>
							{name}
						</option>
					))}
				</select>
			)}
		</div>
	);
};

export default WorkerSelection;

import { CompareResponse, InfoCompareBetweenTwoTxs } from "models/models"
import React from "react"
import "./CompareResult.css"

interface CompareResultProps {
  compareData: CompareResponse
}

const CompareResult: React.FC<CompareResultProps> = (props) => {
  const { compareData } = props
  const { block_1, block_2, comparison } = compareData
  const { txs_compare } = comparison

  const checkFalse = (value: string): string => {
    return value === "false" ? "" : value
  }

  const isMark = (detail: InfoCompareBetweenTwoTxs): boolean => {
    const { index_1, index_2, profit_1, profit_2 } = detail
    return !index_1 || index_1 === "--" || !index_2 || index_2 === "--" || profit_1 !== profit_2 ? true : false
  }

  return (
    <div className="comapre">
      <div>Summary Table</div>
      <table className="comapre-table">
        <thead className="comapre-head">
          <tr>
            <th>Builder Number</th>
            <th>Block Hash</th>
            <th>Timestamp (UTC)</th>
            <th>Worker</th>
            <th>Value</th>
            <th>Block Build Time ms</th>
            <th>Bundle Count</th>
            <th>Private Tx Count</th>
            <th>Tx Count</th>
            <th>Simulation Time</th>
            <th>Response Code</th>
          </tr>
        </thead>
        <tbody className="comapre-body">
          <tr>
            <td>Builder 1</td>
            <td>{block_1.block_hash || ""}</td>
            <td>{block_1.timestamp || ""}</td>
            <td>{block_1.worker || ""}</td>
            <td>{block_1.mev_value || ""}</td>
            <td>{block_1.block_build_time_ms || ""}</td>
            <td>{block_1.bundle_count || ""}</td>
            <td>{block_1.private_tx_count || ""}</td>
            <td>{block_1.tx_count || ""}</td>
            <td>{block_1.simulation_time || ""}</td>
            <td>{block_1.status_code || ""}</td>
          </tr>
          <tr>
            <td>Builder 2</td>
            <td>{block_2.block_hash || ""}</td>
            <td>{block_2.timestamp || ""}</td>
            <td>{block_2.worker || ""}</td>
            <td>{block_2.mev_value || ""}</td>
            <td>{block_2.block_build_time_ms || ""}</td>
            <td>{block_2.bundle_count || ""}</td>
            <td>{block_2.private_tx_count || ""}</td>
            <td>{block_2.tx_count || ""}</td>
            <td>{block_2.simulation_time || ""}</td>
            <td>{block_2.status_code || ""}</td>
          </tr>
        </tbody>
      </table>

      <br />
      <div>Transactions Table</div>
      <table className="comapre-table">
        <thead className="comapre-head">
          <tr>
            <th>Index 1</th>
            <th>Tx Hash</th>
            <th>Index 2</th>
            <th>Profit 1</th>
            <th>Bundle 1</th>
            <th>Uuid 1</th>
            <th>Profit 2</th>
            <th>Bundle 2</th>
            <th>Uuid 2</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody className="comapre-body">
          {txs_compare.map((detail: InfoCompareBetweenTwoTxs, i: number) => (
            <tr key={i} className={`${isMark(detail) && "colored-tr"}`}>
              <td>{detail.index_1}</td>
              <td>{detail.tx_hash}</td>
              <td>{detail.index_2}</td>
              <td>{detail.profit_1}</td>
              <td>{checkFalse(detail.is_bundle_1)}</td>
              <td>{detail.uuid_1}</td>
              <td>{detail.profit_2}</td>
              <td>{checkFalse(detail.is_bundle_2)}</td>
              <td>{detail.uuid_2}</td>
              <td>{detail.uuid_or_bundle_hash || ""}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default CompareResult

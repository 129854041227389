import { StringParam, useQueryParam, useQueryParams } from "use-query-params"

import CompareResult from "components/CompareResult"
import ErrorModal from "components/ErrorModal"
import { BuilderOption, errorText } from "models/enums"
import { CompareResponse, Worker, GetBlocksResponse, OnChainResponse } from "models/models"
import React, { useEffect, useRef, useState } from "react"
import { generateError } from "utils/handleError"
import "./MainPage.css"
import { getSlotStartTime } from "utils/slot"
import Button from "components/Button"
import { compare1Block, compare2Blocks, requestGetBlock } from "utils/requests"
import { getWorkerByHash, initWorkers } from "utils/worker"
import { getQuearyParam } from "utils/url"
import SlotInput from "components/SlotInput"
import Builder from "components/Builder"

const Main = () => {
  const [slotStartTime, setSlotStartTime] = useState<string | undefined>()
console.log('..');

  const [workers, setWorkers] = useState<Worker[]>([])
  const [onChain, setOnChain] = useState<OnChainResponse | undefined>()

  const [selectedBuilder1, setSelectedBuilder1] = useState<Worker | undefined>()
  const [selectedBuilder2, setSelectedBuilder2] = useState<Worker | undefined>()

  const [compareData, setCompareData] = useState<CompareResponse | undefined>()

  const [loadingSlotSubmit, setLoadingSlotSubmit] = useState<boolean>(false)
  const [loadingCompare, setLoadingCompare] = useState<boolean>(false)

  const [error, setError] = useState<string | undefined>()

  const [slot, setSlot] = useQueryParam("slot", StringParam)
  const [b1Hash, setB1Hash] = useQueryParam("b1_hash", StringParam)
  const [b2Hash, setB2Hash] = useQueryParam("b2_hash", StringParam)

  useEffect(() => {
    if (slot) {
      getBlocksBySlot(slot, b1Hash, b2Hash)
    }
  }, [])

  const getBlocksBySlot = async (
    slot: string | undefined,
    builder1Hash?: string | null,
    builder2Hash?: string | null
  ) => {
    clearAll()
    if (slot) {
      try {
        setLoadingSlotSubmit(true)
        const blockesResult: GetBlocksResponse[] | any = await requestGetBlock(slot)
        if (blockesResult) {
          setOnChain(blockesResult.block_onChain)
          const workersResult = initWorkers(blockesResult)
          setWorkers(workersResult)
          setSlotStartTime(getSlotStartTime(slot))

          if (builder2Hash) {
            let builder1: Worker | undefined
            let builder2: Worker | undefined
            builder2 = getWorkerByHash(workersResult, builder2Hash)
            setSelectedBuilder2(builder2)
            if (builder1Hash) {
              builder1 = getWorkerByHash(workersResult, builder1Hash)
              setSelectedBuilder1(builder1)
            }
            getCompareByBlock(slot, builder1, builder2)
          }
        } else {
          setError(errorText.noSlotData)
        }
      } catch (error) {
        setError(generateError(error))
      } finally {
        setLoadingSlotSubmit(false)
      }
    } else {
      setError(errorText.noSlot)
      setLoadingSlotSubmit(false)
    }
  }

  const getCompareByBlock = async (
    slot: string | null | undefined,
    builder1?: Worker | undefined,
    builder2?: Worker | undefined
  ) => {
    try {
      if (slot) {
        let result: CompareResponse | any
        setLoadingCompare(true)
        if (builder2) {
          if (builder1) {
            result = await compare2Blocks(slot, builder1, builder2)
          } else {
            result = await compare1Block(slot, builder2)
          }
        }
        result ? setCompareData(result) : setError(errorText.noData)
      } else {
        setError(errorText.noSlot)
      }
    } catch (error) {
      setError(generateError(error))
    } finally {
      setLoadingCompare(false)
    }
  }

  const handleSlotSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (slot) getBlocksBySlot(slot)
    setB1Hash(undefined)
    setB2Hash(undefined)
  }

  const handleCompareBlocks = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError(undefined)
    setCompareData(undefined)

    getCompareByBlock(slot, selectedBuilder1, selectedBuilder2)
  }

  const clearAll = () => {
    setError(undefined)
    setSlotStartTime(undefined)
    setOnChain(undefined)
    setWorkers([])
    setSelectedBuilder1(undefined)
    setSelectedBuilder2(undefined)
    setCompareData(undefined)
    setLoadingSlotSubmit(true)
  }

  const handleChangeSlot = (slot: string | undefined) => {
    setSlot(slot)
  }

  const handleChangeBuilder1 = (builder: Worker | undefined) => {
    setB1Hash(builder?.block_hash)
    setSelectedBuilder1(builder)
  }

  const handleChangeBuilder2 = (builder: Worker | undefined) => {
    setB2Hash(builder?.block_hash)
    setSelectedBuilder2(builder)
  }

  return (
    <section className="main-container">
      <section className="error-section">{error && <ErrorModal error={error} setError={setError} />}</section>
      
      <SlotInput
        loadingSlotSubmit={loadingSlotSubmit}
        slotStartTime={slotStartTime}
        slot={slot}
        onChangeSlot={handleChangeSlot}
        handleSlotSubmit={handleSlotSubmit}
        onChain={onChain}
      />

      {workers.length !== 0 && (
        <form onSubmit={handleCompareBlocks} className="main-builder-form-container">
          <section className="main-builder-selection">
            <Builder
              title={BuilderOption.Builder1}
              workers={workers}
              onChangeBuilder={handleChangeBuilder1}
              selectedBuilder={selectedBuilder1}
              onChain={onChain}
              isOnChain
            />
            <div className="border" />
            <Builder
              title={BuilderOption.Builder2}
              workers={workers}
              onChangeBuilder={handleChangeBuilder2}
              selectedBuilder={selectedBuilder2}
              onChain={onChain}
            />
          </section>

          <Button title="Compare Blocks" loading={loadingCompare} />
        </form>
      )}

      <section className="main-tables-compare-container">
        {compareData && <CompareResult compareData={compareData} />}
      </section>
    </section>
  )
}

export default Main

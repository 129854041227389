import { Worker } from "models/models"
import React, { useEffect, useState } from "react"
import { sortDate } from "utils/logic"
import { stringifyWorker } from "utils/worker"
import "./BlockSelection.css"

interface BlockSelectionProps {
  workers: Worker[]
  selectedNode: Worker
  onChangeBuilder: (builder: Worker | undefined) => void
  workerName: string
  onchainTime: string | undefined
  selectedBuilder?: Worker | undefined
}

const BlockSelection: React.FC<BlockSelectionProps> = (props) => {
  const { workers, onChangeBuilder, selectedNode, workerName, onchainTime, selectedBuilder = undefined } = props

  const [blockToDisplay, setBlockToDisplay] = useState<string | undefined>()

  useEffect(() => {
    selectedBuilder && setBlockToDisplay(JSON.stringify(selectedBuilder))
  }, [selectedBuilder])

  const handleSelectBlock = (jsonId: string) => {
    setBlockToDisplay(jsonId)
    const worker: Worker = JSON.parse(jsonId)
    onChangeBuilder(worker)
  }

  const getBlocksByWorkerName = (selectedNode: Worker, name: string): Worker[] => {
    let workersArray: Worker[] = []
    workers.forEach((worker: Worker) => {
      if (worker.node_id === selectedNode.node_id) {
        workersArray.push(worker)
      }
    })
    return sortDate(workersArray, name)
  }

  return (
    <select className="select" value={blockToDisplay} onChange={(e) => handleSelectBlock(e.target.value)}>
      <option value="">Select Block</option>
      {getBlocksByWorkerName(selectedNode, workerName).map((worker: Worker, i: number) => (
        <option value={JSON.stringify(worker)} key={i}>
          {stringifyWorker(worker, onchainTime)}
        </option>
      ))}
    </select>
  )
}

export default BlockSelection

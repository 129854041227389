
export enum BuilderOption {
	Builder1 = "Builder 1",
	Builder2 = "Builder 2"
}

export enum errorType {
	error = 'error',
	info = 'info'
}

export enum errorText {
	noData = 'No data has been found for this compare',
	noSlotData = 'No data has been found for this slot number',
	slotError = 'Please provide a valid slot number',
	noSlot = 'Please provide a slot number'
}

export enum Positon {
	right = 'right',
	left = 'left',
	top = 'top',
	bottom = 'bottom'
}
import BuilderSelection from "components/BuilderSelection"
import HashSearch from "components/HashSearch"
import { OnChainResponse, Worker } from "models/models"
import React, { useEffect, useState } from "react"
import "./Builder.css"

interface BuilderProps {
  title: string
  workers: Worker[]
  onChangeBuilder: (builder: Worker | undefined) => void
  selectedBuilder: Worker | undefined
  onChain: OnChainResponse | undefined
  isOnChain?: boolean
}

const Builder: React.FC<BuilderProps> = (props) => {
  const { title, workers, onChangeBuilder, selectedBuilder, onChain, isOnChain = false } = props

  return (
    <section className="builder-container">
      <div>{title}</div>
      <div style={{ fontSize: "18px" }}>
        Select a builder, worker and a block <b>or</b> a block hash
      </div>
      <section className="builer-options-contianer">
        <BuilderSelection
          workers={workers}
          onChangeBuilder={onChangeBuilder}
          selectedBuilder={selectedBuilder}
          onchainTime={onChain?.mev_timestamp}
          isOnChain={isOnChain}
        />
        <div className="border-2" />
        <HashSearch
          workers={workers}
          selectedBuilder={selectedBuilder}
          onChangeBuilder={onChangeBuilder}
          onchainTime={onChain?.mev_timestamp}
        />
      </section>
    </section>
  )
}

export default Builder

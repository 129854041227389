import Button from "components/Button"
import { MevRelay, OnChainResponse } from "models/models"
import React from "react"
import "./SlotInput.css"

interface SlotInputProps {
  loadingSlotSubmit: boolean
  slotStartTime: string | undefined
  slot: any
  onChangeSlot: (slot: any) => void
  handleSlotSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  onChain: OnChainResponse | undefined
}

const SlotInput: React.FC<SlotInputProps> = (props) => {
  const { handleSlotSubmit, loadingSlotSubmit, slotStartTime, slot, onChangeSlot, onChain } = props

  const handleSlotChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChangeSlot(e.currentTarget.value)
  }

  return (
    <form onSubmit={handleSlotSubmit} className="main-slot-form-container">
      <div className="main-title">Add your Slot</div>
      <section>
        <div className="main-slot-container">
          <input
            className="main-slot-input"
            name="slot"
            type="number"
            placeholder="Slot"
            value={slot}
            onChange={handleSlotChange}
          />
          {onChain && (
            <div className="main-on-chain-data">
              {slotStartTime && (
                <div className="main-slot-time main-on-chain-data">
                  <span>Slot time: </span>
                  {slotStartTime} (UTC)
                </div>
              )}
              <div className="main-on-chain-data-option">
                <span>Block hash </span>
                {onChain.block_hash}
              </div>
              <div className="main-on-chain-data-option">
                <span>Value: </span>
                {onChain.mev_value}
              </div>
              <div className="main-on-chain-data-option">
                <span>Build by: </span>
                {onChain.extra_data}
              </div>
              {onChain.mev_relays_timestamp?.map((relay: MevRelay, i: number) => (
                <div className="main-on-chain-data-mev" key={i}>
                  <div className="main-on-chain-data-option">
                    <span>MEV relay:</span>
                    <>{`${relay.relay} (${relay.timestamp})`} </>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
      <Button title="Submit" loading={loadingSlotSubmit} />
    </form>
  )
}

export default SlotInput

import axios from 'axios';

const URL = 'https://data-etl.bxrtest.com/'

const parseUrl = (endpoint: string): string => {
    return `${URL}${endpoint}`
} 

export const reqestGet = async (endpoint: string) => {
	try {
		const result = await axios.get(parseUrl(endpoint));
		return result.data;
	} catch (error) {
		console.error('Catch Error: ', error);
	}
};

export const reqestPost = async (endpoint: string, body: any) => {
	try {
		const result = await axios.get(parseUrl(endpoint), body);
		return result.data;
	} catch (error) {
		console.error('Catch Error: ', error);
	}
};

export const getWorker = async (slat: string, builder: string) => {
	console.log('get worker...')
    return reqestGet(`get-worker?slot=${slat}+builder=${builder}`)
};

export const getBlocks =async (slat: string) => {
	console.log('get blocks...')
	return await reqestGet(`get-blocks?slot=${slat}`)
}

export const getCompareBlock = async (slot: string, builder2?: string, id2?: string, builder1?: string, id1?: string) => {
	console.log('compare block...')
	if(id1){
		return await reqestGet(`compare-block?slot=${slot}+${builder1}=${id1}+${builder2}=${id2}`)
	}
	return await reqestGet(`compare-block?slot=${slot}+${builder2}=${id2}`)
};

import { NodeOptions, Worker } from "models/models";

export const sortString = (option1: string, option2: string): number => {
    if (option1 < option2) {
        return -1;
    }
    if (option1 > option2) {
        return 1;
    }
    return 0;
}

export const sortDate = (options: Worker[], value: string): Worker[] => {
    return options
        .filter((w: Worker) => {
            if (w.worker_id.name === value) {
                return w;
            }
        })
        .sort((num1, num2) => num1.worker_id.number - num2.worker_id.number)
        .reverse();
}

export const removeDuplicates = (options: NodeOptions[]): NodeOptions[] => {
    return options.filter(
        (item: NodeOptions, index: number, self: NodeOptions[]) =>
            index === self.findIndex((t) => t.display === item.display)
    );
}
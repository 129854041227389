import { errorType } from 'models/enums';
import { useEffect, useRef, useState } from 'react';
import './ErrorModal.css';

interface ErrorModalProps {
	error: string;
	setError: React.Dispatch<React.SetStateAction<string | undefined>>;
	type?: errorType;
}

const ErrorModal: React.FC<ErrorModalProps> = (props) => {
	const { error, setError, type = errorType.error } = props;
	const [ errorToggle, setErrorToggle ] = useState<boolean>(false);
	const modal = useRef<any>();

	const handleClick = (e: MouseEvent) => {
		if (modal.current && !modal.current.contains(e.target)) {
			// outside click
			setError(undefined);
			setErrorToggle(false);
		}
	};

	useEffect(() => {
		setErrorToggle(true)
		document.addEventListener('mousedown', handleClick);
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	return errorToggle ? (<section className={`error-container error-${type}`} ref={modal}>{error}</section>) : <div></div>;
};

export default ErrorModal;

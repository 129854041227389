import React from 'react';
import './Button.css';

interface ButtonProps {
	title: string;
    loading: boolean
	onClick?: any;
}

const Button: React.FC<ButtonProps> = (props) => {
	const { title, loading, onClick = ()=> {} } = props;

	return (
		<div className="btn-container">
			{loading ? (
				<div className="loading">Loading...</div>
			) : (
				<button className="btn" type="submit" onClick={onClick}>
					{title}
				</button>
			)}
		</div>
	);
};

export default Button;

import { Positon } from "models/enums"
import { Worker } from "models/models"
import React, { useEffect, useRef, useState } from "react"
import { getFilterdWorkers, removeDuplicated } from "utils/hash"
import { getWorkerByHash, stringifyWorkerTime } from "utils/worker"
import "./HashSearch.css"

interface HashSearchProps {
  workers: Worker[]
  selectedBuilder: Worker | undefined
  onChangeBuilder: (builder: Worker | undefined) => void
  onchainTime: string | undefined
}

const HashSearch: React.FC<HashSearchProps> = (props) => {
  const { workers, selectedBuilder, onChangeBuilder, onchainTime } = props
  const [results, setResults] = useState<Worker[]>([])
  const [optionsToggle, setOptionsToggle] = useState<boolean>(false)
  const [displayNode, setDisplayNode] = useState<string | undefined>()
  const [optionsPosition, setOptionsPosition] = useState<string>(Positon.left)
  const inputRef = useRef<any>()
  const optionsRef = useRef<any>()

  useEffect(() => {
    selectedBuilder
      ? setDisplayNode(`${selectedBuilder.node_id} - ${selectedBuilder.value}, ${selectedBuilder.builder_timestamp}
						(${stringifyWorkerTime(selectedBuilder.status_code_1, selectedBuilder.relay_time_1, onchainTime)} -
					${stringifyWorkerTime(selectedBuilder.status_code_2, selectedBuilder.relay_time_2, onchainTime)})`)
      : setDisplayNode(undefined)
  }, [selectedBuilder])

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOptionsToggle(true)
    getOptionsPostion()
    onChangeBuilder(undefined)
    setDisplayNode(undefined)
    const value = e.target.value.toLowerCase()

    const firstSearchFilterd = getFilterdWorkers(workers, value)
    const finalSearchFilterd = removeDuplicated(firstSearchFilterd)

    setResults(finalSearchFilterd)

    const result = getWorkerByHash(workers, value)
    setResualt(result)
  }

  const onClickHash = (block: Worker) => {
    setResualt(block)
    setOptionsToggle(false)
  }

  const setResualt = (result: Worker | undefined) => {
    result && onChangeBuilder(result)
  }

  const handleClick = (e: MouseEvent) => {
    if (optionsRef.current && !optionsRef.current.contains(e.target)) {
      // outside click
      setOptionsToggle(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)
    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  const getOptionsPostion = () => {
    const inputBoundingRect = inputRef.current?.getBoundingClientRect()
    const position = inputBoundingRect ? (inputBoundingRect.left <= 200 ? Positon.left : Positon.right) : Positon.left
    setOptionsPosition(position)
  }

  return (
    <section className="hash-container">
      <input
        placeholder="Block hash"
        name="hash"
        autoComplete="off"
        value={selectedBuilder ? selectedBuilder?.block_hash : ""}
        onChange={onChangeSearch}
        className="hash-input"
        ref={inputRef}
      />
      {displayNode && <div className="hash-node">{displayNode}</div>}
      {optionsToggle && (
        <section className={`hash-options hash-options-${optionsPosition}`} ref={optionsRef}>
          <ul>
            {results.map((worker: Worker, i: number) => (
              <li key={i} onClick={() => onClickHash(worker)}>
                {`${worker.block_hash} - ${worker.value}, ${worker.builder_timestamp}
								(${stringifyWorkerTime(worker.status_code_1, worker.relay_time_1, onchainTime)} -
								${stringifyWorkerTime(worker.status_code_2, worker.relay_time_2, onchainTime)})`}
              </li>
            ))}
          </ul>
        </section>
      )}
    </section>
  )
}

export default HashSearch

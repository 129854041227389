import { ON_CHAIN } from "models/constant"
import { NodeOptions, Worker } from "models/models"
import React, { useEffect, useState } from "react"
import { genrateNodeOptions } from "utils/node"
import "./NodeSelection.css"

interface NodeSelectionProps {
  workers: Worker[]
  setSelectedNode: React.Dispatch<React.SetStateAction<Worker | undefined>>
  onChangeBuilder: (builder: Worker | undefined) => void
  selectedBuilder?: Worker | undefined
  isOnChain?: boolean
}

const NodeSelection: React.FC<NodeSelectionProps> = (props) => {
  const { workers, setSelectedNode, onChangeBuilder, selectedBuilder = undefined, isOnChain = false } = props

  const [nodeToDisplay, setNodeToDisplay] = useState<string | undefined>()

  useEffect(() => {
    if (selectedBuilder) {
      setNodeToDisplay(selectedBuilder.node_id)
      setSelectedNode(selectedBuilder)
    }
  }, [selectedBuilder])

  const selectNode = (selectedNode: string) => {
    const result = workers.find((worker: Worker) => {
      return worker.node_id === selectedNode
    })
    setSelectedNode(result)
    !result && onChangeBuilder(undefined)
    setNodeToDisplay(selectedNode)
  }

  return (
    <select className="select" value={nodeToDisplay} onChange={(e) => selectNode(e.target.value)}>
      {genrateNodeOptions(workers, isOnChain).map((node: NodeOptions, i: number) => (
        <option key={i} value={node.value}>
          {node.display}
        </option>
      ))}
    </select>
  )
}

export default NodeSelection

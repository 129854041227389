import { CompareResponse, GetBlocksResponse, Worker } from "models/models";
import { getBlocks, getCompareBlock } from "./api";
import { getBlocks_Mock } from 'models/mocks';
import { stringifyWorkerId } from "./worker";

export const requestGetBlock = async (slot: string) =>{
    const blockesResult: GetBlocksResponse[] | any = await getBlocks(slot);
    //  const blockesResult: GetBlocksResponse[] | any = getBlocks_Mock;
    return blockesResult
}

export const compare2Blocks = async (slot: string, builder1: Worker, builder2: Worker) => {
    const result: CompareResponse | any = await getCompareBlock(
        slot,
        builder1.node_id,
        stringifyWorkerId(builder1.worker_id),
        builder2.node_id,
        stringifyWorkerId(builder2.worker_id)
    );
    return result;
};

export const compare1Block = async (slot: string, builder: Worker) => {
    const result: CompareResponse | any = await getCompareBlock(
        slot,
        builder.node_id,
        stringifyWorkerId(builder.worker_id)
    );
    return result;
};
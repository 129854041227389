
import { Worker } from "models/models";
import { GetBlocksResponse } from "models/models";
import { sortString } from "./logic";
import { dateByTime } from "./time";

export const getFilterdWorkers = (workers: Worker[], value: string): Worker[] => {
    const filterd = workers.filter((worker: Worker) => {
        return value === '' ? worker : worker.block_hash.toLowerCase().includes(value);
    });
    const sorted = filterd.sort((option1, option2) => {
        return sortString(option1.value, option2.value)
    }).reverse()
    return sorted
}

export const removeDuplicated = (workers: Worker[]): Worker[] => {
    const dict: Record<string, Worker[]> = {};

    workers.forEach((worker: Worker) => {
        const hash = worker.block_hash;
        if (dict[hash]) {
            dict[hash].push(worker)
        } else {
            dict[hash] = [worker]
        }
    })

    const finalArray: Worker[] = [];
    Object.entries(dict).forEach((opt) => {
        const currentArray = opt[1];
        currentArray.sort((a, b) => {
            const dateA = dateByTime(a.builder_timestamp);
            const dateB = dateByTime(b.builder_timestamp);
            return dateA.getTime() - dateB.getTime();
        })

        finalArray.push(currentArray[0]);
    })

    return finalArray;
}
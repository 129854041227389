import { WorkerId, Worker, GetBlocksResponse, BlockResponse } from "models/models";
import { dateByTime } from "./time";


export const initWorkerId = (worker_id: string): WorkerId => {
    const splitId = worker_id.split('-');
    const workerIdResult: WorkerId = {
        slot: parseInt(splitId[0]),
        name: splitId[1],
        number: parseInt(splitId[2])
    }
    return workerIdResult
}

export const stringifyWorkerId = (id: WorkerId | undefined): string => {
    return id ? `${id.slot}-${id.name}-${id.number}` : ''
}

export const stringifyWorker = (worker: Worker, onchainTime: string | undefined): string => {
    const { worker_id, builder_timestamp, value, relay_time_1, relay_time_2, status_code_1, status_code_2, get_header } = worker
    return `${worker_id.number} - ${value}, ${builder_timestamp}
        (${stringifyWorkerTime(status_code_1, relay_time_1, onchainTime)}  - 
        ${stringifyWorkerTime(status_code_2, relay_time_2, onchainTime)}) 
        ${get_header ? '***' : ''}`
}

export const stringifyWorkerTime = (statusCode: string | undefined, relayTime: string | undefined, onchainTime: string | undefined): string => {
    return `${relayTime || ''} ${isV(statusCode, relayTime, onchainTime)}`
}

export const isV = (statusCode: string | undefined, relayTime: string | undefined, onchainTime: string | undefined): string => {
    if (statusCode && statusCode === "200") {
        if (onchainTime && relayTime) {
            const onChainTime = dateByTime(onchainTime);
            const blockTime = dateByTime(relayTime);
            return  blockTime > onChainTime ? `✓` : '';
        }
    }
    return '';
}

export const initWorkers = (blocks: GetBlocksResponse): Worker[] => {
    let workers: Worker[] = [];
    blocks.blocks_ids.forEach((block: BlockResponse) => {
        const result = initWorker(block);
        result && workers.push(result);
    })
    return workers;
}

export const initWorker = (block: BlockResponse | undefined): Worker | undefined => {
    if (block) {
        const worker_id = initWorkerId(block.worker_id);
        const worker: Worker = {
            block_hash: block.block_hash,
            node_id: block.node_id,
            worker_id: worker_id,
            value: block.value,
            builder_timestamp: block.builder_timestamp,
            relay_time_1: block.relay_time_1,
            relay_time_2: block.relay_time_2,
            status_code_1: block.status_code_1,
            status_code_2: block.status_code_2,
            get_header: block.get_header || false
        };
        return worker
    }
}

export const getWorkerByHash = (workers: Worker[], hash: string): Worker | undefined => {
    return workers.find((worker: Worker) => {
        return worker.block_hash === hash
    })
}

import { OnChainResponse, Worker } from "models/models"
import React, { useEffect, useState } from "react"
import BlockSelection from "./BlockSelection"
import "./BuilderSelection.css"
import NodeSelection from "./NodeSelection"
import WorkerSelection from "./WorkerSelection"

interface BuilderSelectionProps {
  workers: Worker[]
  onChangeBuilder: (builder: Worker | undefined) => void
  selectedBuilder?: Worker | undefined
  onchainTime: string | undefined
  isOnChain?: boolean
}

const BuilderSelection: React.FC<BuilderSelectionProps> = (props) => {
  const { workers, onChangeBuilder, selectedBuilder = undefined, onchainTime, isOnChain = false } = props

  const [selectedNode, setSelectedNode] = useState<Worker | undefined>()

  const [workerName, setWorkerName] = useState<string>("")

  return (
    <section className="select-container">
      <NodeSelection
        workers={workers}
        setSelectedNode={setSelectedNode}
        onChangeBuilder={onChangeBuilder}
        selectedBuilder={selectedBuilder}
        isOnChain={isOnChain}
      />
      {selectedNode && (
        <WorkerSelection
          workers={workers}
          selectedNode={selectedNode}
          setWorker={setWorkerName}
          selectedBuilder={selectedBuilder}
        />
      )}

      {selectedNode && workerName && (
        <BlockSelection
          workers={workers}
          selectedNode={selectedNode}
          workerName={workerName}
          onChangeBuilder={onChangeBuilder}
          selectedBuilder={selectedBuilder}
          onchainTime={onchainTime}
        />
      )}
    </section>
  )
}

export default BuilderSelection

import Main from "components/MainPage"

import { QueryParamProvider } from "use-query-params"
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6"
import { BrowserRouter, Route, Routes } from "react-router-dom"
// optionally use the query-string parse / stringify functions to
// handle more advanced cases than URLSearchParams supports.
import queryString from "query-string"

const App = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: queryString.parse,
          objectToSearchString: queryString.stringify,
        }}
      >
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default App

import { NO_OPTION, ON_CHAIN } from "models/constant";
import { NodeOptions, Worker } from "models/models";
import { removeDuplicates, sortString } from "./logic";

export const genrateNodeOptions = (workers: Worker[], isOnChain: boolean = false): NodeOptions[] => {
    const nodesOptions: NodeOptions[] = [];

    isOnChain ? nodesOptions.push(ON_CHAIN) : nodesOptions.push(NO_OPTION);

    workers.forEach((block: Worker) => {
        const option: NodeOptions = {
            value: block.node_id,
            display: block.node_id
        };
        nodesOptions.push(option);
    });
    const filterdNodeOptions = removeDuplicates(nodesOptions)

    const sortNodeOptions = filterdNodeOptions.sort((val1, val2) => {
        return sortString(val1.display, val2.display)
    })

    return sortNodeOptions;
};
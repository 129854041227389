import { NodeOptions } from "./models";

export const START_SLOT_DATE = 1606824023;

export const ON_CHAIN: NodeOptions = {
    value: 'on-chain',
    display: 'On Chain'
}

export const NO_OPTION: NodeOptions = {
    value: 'empty',
    display: 'Select Builder'
}
import moment from "moment"


export const dateByTime = (time: string): Date => {
   return new Date(moment(time, "HH:mm:ss.SSS").valueOf())
}

export const timeByTimestamp = (timestamp: number): string  => {
	return moment.unix(timestamp).utc().format('MMM Do YYYY, HH:mm:ss.SSS');
}

export const timeByTime = (time: string): string => {
   return  moment(time, "HH:mm:ss.SSS").utc().format('HH:mm:ss.SS');
}